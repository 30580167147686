import React from "react";
import Sidebar from "../../Layout/Sidebar";
import style from "../../Styles/category.module.css";
import TableSubCategory from "./TableSubCategory";
import { ToastContainer } from "react-toastify";

const SubCategory = () => {
  return (
    <>
<section className={`${style.home}`}>
        <Sidebar active="category" />
        <div className={`${style.homeContainer}`}>
         <TableSubCategory/>
        </div>
        <ToastContainer/>
      </section>
    </>
  )
}

export default SubCategory