import React, { useState, useEffect } from "react";
import {
  MRT_GlobalFilterTextField,
  MRT_TableBodyCellValue,
  MRT_TablePagination,
  MRT_ToolbarAlertBanner,
  flexRender,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {Modal, Button } from '@mantine/core';

import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import style from '../../Styles/category.module.css'
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Environment from '../../config';
import useSWR, { mutate, useSWRConfig } from 'swr'
import { useDisclosure } from '@mantine/hooks';

const TableUsers = () => {
    const [data, setUsers] = useState([]);
    const [opened, { open, close }] = useDisclosure(false);
  const { token } = useSelector((state) => state.user);
  const[loading,setLoading]=useState()
  const fetcher = (args) => {
    const [url, token] = args
    return axios
      .get(url, { headers: { Authorization: `Bearer ${ token }` }
})
      .then((response) => {
        console.log(response,'kjh')
        setUsers(response.data.data);
        return response.data.data 
      }  
      )
   }
 const { category, error ,isLoading} = useSWR(
  [`${Environment.baseURL}/api/ManageUsers/getAll`, token],
  fetcher, 
);
if (error) console.log(error);

if (data){
  console.log(data, 'swr');
  
} 
const handlerReset = (id) => {
    axios
    .post(`${Environment.baseURL}/api/ManageUsers/resetUserPassword?id=${id}`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then((response) => {
      if(response.data.success){
        close()
        toast.success(' resete password successfully');
      }else{
        close()
        toast.error(response.data.errorMessage);
      }

           

    })
    .catch((err) => {
        toast.error(err.response.data.errorMessage
);
        console.log(err)

    });
  };

const columns = [

    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "mobile",
      header: "Mobile",
    },
    {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "registerType",
        header: "Register Type",
      },
    {
      accessorKey: "Action",
      header: "Action",
      columnDefType: "display",
      enableColumnOrdering: 1,
      Cell: ({ renderedCellValue, row, cell }) => (
        <>
         <Modal opened={opened} onClose={close} title={` Reset Password`} >

<p>Are You Sure you want Reset Password ?</p>

                    
                    <Button type='button' onClick={() => handlerReset(`${row.original.id}`)}>Reset Password</Button>
                   
      </Modal>

      <Button onClick={open}> Reset Password</Button>
        </>
      ),
    },
  ];
  const table = useMaterialReactTable({
    columns,
    data, 
    enableRowSelection: false,
    initialState: {
        pagination: { pageSize: 20, pageIndex: 0 },
        showGlobalFilter: true,
    },
    muiPaginationProps: {
        rowsPerPageOptions: [5, 10, 15],
        variant: 'outlined',
    },
    paginationDisplayMode: 'pages',
});
if (isLoading) return( <>
<section className="loaduser">
<div class="loader">
<div class="loader-square"></div>
<div class="loader-square"></div>
<div class="loader-square"></div>
<div class="loader-square"></div>
<div class="loader-square"></div>
<div class="loader-square"></div>
<div class="loader-square"></div>
</div>
<p>Loading users please wait....</p>
</section>
</>
)
return (
    <>

    
    <Stack sx={{ m: ' 0' }}>
        <Typography variant="h4">Users details</Typography>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >

            <MRT_GlobalFilterTextField table={table} />
            <MRT_TablePagination table={table} />
        </Box>
        <TableContainer>
            <Table>
                <TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <TableCell align="center" variant="head" key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.Header ??
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.map((row, rowIndex) => (
                        <TableRow key={row.id} selected={row.getIsSelected()}>
                            {row.getVisibleCells().map((cell, _columnIndex) => (
                                <TableCell align="center" variant="body" key={cell.id}>
                                    {/* Use MRT's cell renderer that provides better logic than flexRender */}
                                    <MRT_TableBodyCellValue
                                        cell={cell}
                                        table={table}
                                        staticRowIndex={rowIndex} //just for batch row selection to work
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <MRT_ToolbarAlertBanner stackAlertBanner table={table} />
    </Stack>
    
   <ToastContainer/>
                </>
);
}

export default TableUsers
