import React, { useState, useRef, useEffect } from "react";
import { Select, TextInput, Textarea } from "@mantine/core";
import styles from '../../Styles/contactUs.module.css'
import Sidebar from '../../Layout/Sidebar'
import { useSelector } from "react-redux";
import axios from "axios";
import Environment from '../../config';
import { Button } from '@mantine/core';
import { Col, Row } from "react-bootstrap";
import { mutate } from "swr";
import { toast } from 'react-toastify'
import { ToastContainer } from "react-toastify";
const ContactUs = () => {
    const { token } = useSelector((state) => state.user);
    const [selectedOption, setSelectedOption] = useState(``);
    const [selectLang, setSelect] = useState([]);
    const [title, setTitle] = useState("");
    const [textarea, setTextarea] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [face, setFace] = useState("");
    const [insta, setInsta] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [tele, setTele] = useState("");

    useEffect(() => {
        axios
            .get(
                `${Environment.baseURL}/api/admin/AdminOptions/getLanguage`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                setSelect(response.data.data);
                const filteredData = response.data.data.filter(item => item.defaultFlage === 1);
                setSelectedOption(filteredData.length > 0 ? filteredData[0].code : null);
            })
            .catch((err) => {
                console.log(err.response);
            });

            axios
            .get(
                `${Environment.baseURL}/api/ContactUs/getContactUsInfo`,
                {
                  
                }
            )
            .then((response) => {
                setPhone(response.data.data.callTelNo)
                setEmail(response.data.data.sendEmail)
                setFace(response.data.data.socialFaceBook)
                setTele(response.data.data.socialTelegram)
                setInsta(response.data.data.socialInsta)
                setWhatsapp(response.data.data.whatsTelNo)
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, []);
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        const SelectedOption = parseInt(event.target.value);
/*         const selectedItem = itemLangDetails.find((item) => item.languageCode === SelectedOption);
        setTitle(selectedItem?.itemName ?? "");
        setTextarea(selectedItem?.itemDesc ?? ""); */
    };
    console.log(selectedOption)
    function submitDetails(e) {
        e.preventDefault();
        const reqData = {
            "languageCode": selectedOption,
            "ourPolicy": title
        };
        axios
            .post(`${Environment.baseURL}/api/admin/OurPolicy/manageOurPolicy`, reqData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                console.log(response)
                toast.success('add privacy Successfully');
            
            })
            .catch((err) => {
                toast.error(err);
            });
    }
    function submitDetailsSocial(e) {
        e.preventDefault();
        const reqData = {
            "callTelNo": phone,
            "sendEmail": email,
            "socialFaceBook": face,
            "socialInsta": insta,
            "socialTelegram": tele,
            "whatsTelNo": whatsapp,

          }
        axios
            .post(`${Environment.baseURL}/api/admin/AdminContactUs/manage`, reqData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                console.log(response)
                toast.success('add Social Successfully');
                mutate([
                    `${Environment.baseURL}/api/ContactUs/getContactUsInfo`,
                    
                  ]);
            })
            .catch((err) => {
                toast.error(err);
            });
    }
  return (
    <>
<section className={`${styles.home}`}>
        <Sidebar active="contactus" />
        <div className={`${styles.homeContainer}`}>
            <h3>Contact Us Information</h3>
            <Row>
                <Col>
        <div className={`${styles.base__body}`}>
              <select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className={`${styles.select}`}
              >
                  <option>Choose Language</option>

                  {selectLang.map((option) => (
                      <option key={option.code} value={option.code}>
                          {option.name}
                      </option>
                  ))}
              </select>
            
                  <>
                      <Textarea
                          className={`${styles.title}`}
                          label={`privacy policy`}
                          value={title}
                          onChange={(event) => setTitle(event.currentTarget.value)}
                          autosize
                          minRows={4}
                          maxRows={5}
                      />
                      <TextInput
                          className={`${styles.description}`}
                          label={`Notification Massage for wallet`}
                          value={textarea}
                          onChange={(event) => setTextarea(event.currentTarget.value)}
                        
                      />
                  </>
          </div> 
          <div className={`${styles.sub__btn}`}>
                  <Button type="button" onClick={submitDetails}>Submit Privacy policy</Button>
              </div>
          </Col>
          <Col>
          <div className={`${styles.base__body}`}>
            
                  <>
                    
                      <TextInput
                          className={`${styles.description}`}
                          label={`Phone Number`}
                          value={phone}
                          onChange={(event) => setPhone(event.currentTarget.value)}
                        
                      />
                       <TextInput
                          className={`${styles.description}`}
                          label={`Email`}
                          value={email}
                          onChange={(event) => setEmail(event.currentTarget.value)}
                        
                      />
                       <TextInput
                          className={`${styles.description}`}
                          label={`Facebook Link`}
                          value={face}
                          onChange={(event) => setFace(event.currentTarget.value)}
                        
                      />
                       <TextInput
                          className={`${styles.description}`}
                          label={`Instagram Link`}
                          value={insta}
                          onChange={(event) => setInsta(event.currentTarget.value)}
                        
                      />
                       <TextInput
                          className={`${styles.description}`}
                          label={`Whatsapp Link`}
                          value={whatsapp}
                          onChange={(event) => setWhatsapp(event.currentTarget.value)}
                        
                      />
                       <TextInput
                          className={`${styles.description}`}
                          label={`Telegram Link`}
                          value={tele}
                          onChange={(event) => setTele(event.currentTarget.value)}
                        
                      />
                  </>

             
          </div> 
          <div className={`${styles.sub__btn}`}>
          <Button type="button" onClick={submitDetailsSocial}>Submit Social</Button>
              </div>
          </Col>
          
          </Row>
        </div>
        <ToastContainer/>
        </section>
    </>
  )
}

export default ContactUs