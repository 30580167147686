import Sidebar from "../../Layout/Sidebar";
import styles from "../../Styles/branch.module.css";
import { useMemo, useEffect, useState, useRef } from "react";
import JoditEditor from "jodit-react";
import Environment from "../../config";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import uploadimg from "../../assets/images/upload.jpg";
import { Col, Row } from "react-bootstrap";
import {Textarea} from "@mantine/core";
import { Button } from '@mantine/core';
import { toast } from 'react-toastify'
import { ToastContainer } from "react-toastify";
export const editorConfig = {
  readonly: false,
  toolbar: true,
  spellcheck: true,
  language: "en",
  toolbarButtonSize: "medium",
  // toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,
  //defaultActionOnPaste: "insert_clear_html",
  // buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true,
  },
  width: 800,
  minHeight: 400,
  controls: {
    font: {
      command: "fontname",
      list: {
        "'Open Sans',sans-serif": "Open Sans",
        "Helvetica,sans-serif": "Helvetica",
        "Arial,Helvetica,sans-serif": "Arial",
        "Georgia,serif": "Georgia",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
        "Consolas,monaco,monospace": "Consolas",
      },
    },
  },
};
const NewBlog = () => {
    const [initialContent, setInitialContent] = useState();
    const { token } = useSelector((state) => state.user);
    const [dataBlog, setDataBlog] = useState();
    const [dataBlogsucess, setDataBlogsucess] = useState(false);
    const blogId = useParams();
    const [title, setTitle] = useState();
    const [formData, setFormData] = useState({
      img: ``,
    });
    const addFile = useRef(null);
    const addFileInput = useRef(null);
    const imageContentRef = useRef(null);
    const imageFirmRef = useRef(null);
    const [imageUrl, setImage] = useState(null);
    const[imageChange,setImageChane]=useState(false)
    function handleLogo() {
      let inputFileEvent = document.querySelector(".input-file-js");
      inputFileEvent.click();
      setImageChane(true)
    }
    let previewUploadImage = (e) => {
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      let preViewLink = URL.createObjectURL(file);
      setImage(preViewLink);
    
      setFormData((prevValue) => {
        return {
          ...prevValue,
          img: file,
        };
      });
     
    };
  

    
    function submitDetails(e) {
      e.preventDefault();
    setDataBlogsucess(true)
    console.log(formData.img ,imageChange)
      const reqData = {
          Id: null,
          TitleText: title,
          MainText: initialContent,
          BlogImage: formData.img,
          token:token,
      ImageUrl:null
  
      };
  
      
   
      axios
          .post(`${Environment.baseURL2}/api/Blog/manageBlog`, reqData, {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data'
              }
          })
          .then((response) => {
              console.log(response)
       
        if(response.data.success){
          setDataBlogsucess(false)
              toast.success('New Blog Success');
        }
          
          })
          .catch((err) => {
              toast.error(err);
          });
  
  }
    return (
      <>
        <section className={`${styles.home}`}>
          <Sidebar active="blog" />
          <div className={`${styles.homeContainer}`}>
            <Row>
              <Col>
              
                         <Textarea
                            className={`${styles.title} mb-4`}
                            label={`Title of Blog`}
                            value={title}
                            onChange={(event) => setTitle(event.currentTarget.value)}
                            autosize
                            minRows={4}
                            maxRows={5}
                        />
                <div className={`${styles.im}`}>
                <p>Image of Blog</p>
                  <input
                    className={`${styles.fileImg}  input-file-js`}
                    ref={(e) => {
                      addFileInput.current = e;
                    }}
                    id="input-file"
                    name="img"
                    type="file"
                    onChange={(e) => {
                      previewUploadImage(e);
                    }}
                  />
                  {imageUrl == null ? (
                    <>
                      <div
                        ref={addFile}
                        onClick={() => {
                          handleLogo();
                        }}
                      >
                        <img
                          alt=""
                          src={uploadimg}
                          ref={imageFirmRef}
                          className={styles.uploadimges}
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      ref={addFile}
                      onClick={() => {
                        handleLogo();
                      }}
                    >
                      <img
                        className={`${styles.img}`}
                        ref={imageContentRef}
                        src={imageUrl}
                        alt=""
                      />
                    </div>
                  )}
                </div>
                {
                  dataBlogsucess?
                  <p>loading....</p>
                  :
                  <Button variant="filled" className="mt-4" type="button" onClick={submitDetails}>Submit Blog</Button>
  
                }
  
              </Col>
              <Col>
                <div
                  className="App"
                  style={{ maxWidth: editorConfig.width, margin: "0 auto" }}
                >
                  <p>Main of Blog</p>
                  <JoditEditor
                    value={initialContent}
                    config={editorConfig}
                    onChange={(value) => setInitialContent(value)}
                    onBlur={(value, event) => console.log(event)}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <ToastContainer/>
        </section>
      </>
    );
}

export default NewBlog