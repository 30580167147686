import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import style from "../../Styles/color.module.css";
import axios from "axios";
import { useSelector } from "react-redux";
import Environment from "../../config";
import {Button } from '@mantine/core';
import Modal from "react-bootstrap/Modal";
import { ColorPicker, Text } from "@mantine/core";
import Form from "react-bootstrap/Form";
import { Pagination } from "@mantine/core";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import styles from '../../Styles/category.module.css'
import {  TextInput } from "@mantine/core";

const Color = () => {
  const [data, setData] = useState([]);
  const [colorUpdated, setColorUpdated] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(30);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [displayedPosts, setDisplayedPosts] = useState([]);
  const indexofLastPost = currentPage * postsPerPage;
  const indexofFirstPost = indexofLastPost - postsPerPage;
  const pageNumbers = [];
  const [title, setTitle] = useState('');

  const [selectedOption, setSelectedOption] = useState(``);
  const [selectLang, setSelect] = useState([]);
  console.log(title)
  useEffect(() => {
      axios
          .get(
              `${Environment.baseURL}/api/admin/AdminOptions/getLanguage`,
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
              }
          )
          .then((response) => {
              setSelect(response.data.data);
              const filteredData = response.data.data.filter(item => item.defaultFlage === 1);
              setSelectedOption(filteredData.length > 0 ? filteredData[0].code : null);
          })
          .catch((err) => {
              console.log(err.response);
          });
  }, []);
  for (let i = 1; i <= Math.ceil(searchResults.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [selectedColor, setSelectedColor] = useState(null);
  const [value, setValue] = useState("");
  const handleClose = () => setSelectedColor(null);
  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    if (searchTerm) {
      const lowercaseSearchTerm = searchTerm.toLowerCase();
      setSearchTerm(lowercaseSearchTerm);
    } else {
      setSearchTerm("");
    }
  };
  useEffect(() => {
    axios
      .get(`${Environment.baseURL}/api/admin/Color/getColors`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
        setColorUpdated(false);
        console.log(response.data.data)
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [colorUpdated]);
  useEffect(() => {
    const filteredResults = data.filter((co) => {
      const code = co.code && co.code.toString().toLowerCase();
      const name = co.name && co.name.toLowerCase();
      return code.includes(searchTerm) || name.includes(searchTerm);
    });
    setSearchResults(filteredResults);
  }, [data, searchTerm]);

  useEffect(() => {
    const displayed = searchResults.slice(indexofFirstPost, indexofLastPost);
    setDisplayedPosts(displayed);
  }, [searchResults, indexofFirstPost, indexofLastPost]);

  useEffect(() => {
    const initialDisplayed = data.slice(indexofFirstPost, indexofLastPost);
    setDisplayedPosts(initialDisplayed);
  }, [data, indexofFirstPost, indexofLastPost]);

  function submitColor(e) {
    e.preventDefault();
    const reqData = {
      code: selectedColor.code,
      colorHex: value.replace('#', ''),
    };
const reqData2={
  "languageCode": selectedOption,
  "colorCode": selectedColor.code,
  "colorName": title?title:selectedColor.name
}
if(title!=''){
  axios
  .post(
    `${Environment.baseURL}/api/admin/Color/addColorLangDetails
    `,
    reqData2,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  )
  .then((response) => {
    console.log(response);
    if (response.status == 200) {
      toast.success("Color Edited successfully");
      handleClose();
      setColorUpdated(true);
    }
  })
  .catch((err) => {
    toast.error(err.response.data.message);
    console.log(err);
  });
}

      if(value!=''){
        axios
      .post(
        `${Environment.baseURL}/api/admin/Color/addColor
        `,
        reqData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          toast.success("Color Edited successfully");
          handleClose();
          setColorUpdated(true);

        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        console.log(err);
      });
      }
  }
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
};

useEffect(() => {
 if(selectedColor){
  axios
          .get(
              `${Environment.baseURL}/api/admin/Color/manageColors?color=${selectedColor.code}`,
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
              }
          )
          .then((response) => {
              const filteredData = response.data.data.find(item => item.languageCode == selectedOption);
              setTitle(filteredData?.colorName);
              console.log(filteredData,'filteredData.colorName')
          })
          .catch((err) => {
              console.log(err.response);
          });
 }
}, [selectedColor, selectedOption]);
  return (
    <>
      <section className={`${style.home}`}>
        <Sidebar active="color" />
        <div className={`${style.homeContainer}`}>
          <div className={`${style.filter__body}`}>
            <div class="searchbar">
              <div class="searchbar-wrapper">
                <div class="searchbar-left">
                  <div class="search-icon-wrapper">
                    <span class="search-icon searchbar-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div class="searchbar-center">
                  <div class="searchbar-input-spacer"></div>
                  <input
                    class="searchbar-input"
                    type="text"
                    placeholder="Search by ID or Name"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
            <div className={`${style.paginate__body}`}>
              {/*           {pageNumbers.map(number => (
              <Button onClick={() => paginate(number)} key={number} className='pagiBtn'>{number}</Button>

          ))} */}
              <Pagination
                total={pageNumbers.length}
                value={currentPage}
                onChange={setCurrentPage}
                color="rgba(217, 217, 217, 1)"
                radius="lg"
              />
            </div>
          </div>

          <div className={`${style.color__body}`}>
            {displayedPosts.map((color) => (
              <>
                <div
                  className={`${style.color_style}`}
                  key={color.code}
                  onClick={() => {
                    setSelectedColor(color);
                    setValue(color.colorHex);
                  }}
                >
                  {color.colorHex.includes("#") ? (
  <div
    style={{ backgroundColor: color.colorHex }}
    className={style.color}
  ></div>
) : (
  <div
    style={{ backgroundColor: `#${color.colorHex}` }}
    className={style.color}
  ></div>
)}
                  {color.code} - {color.name}
                </div>
              </>
            ))}
          </div>
          {selectedColor && (
            <Modal show={true} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Color Details ID: {selectedColor.code}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className={`${style.modal__body}`}>
                
              <select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className={`${styles.select}`}
              >
                  <option>Choose Language</option>

                  {selectLang.map((option) => (
                      <option key={option.code} value={option.code}>
                          {option.name}
                      </option>
                  ))}
              </select>
              <TextInput
                          className={`${styles.title} mb-3`}
                          label={`Title `}
                          value={title}
                          onChange={(event) => setTitle(event.currentTarget.value)}
                      />
                <ColorPicker value={value} onChange={setValue} />
              
              
              
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="secondary" onClick={submitColor}>
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      </section>
    </>
  );
};

export default Color;
