import React from 'react'
import styles from '../../Styles/user.module.css'
import Sidebar from '../../Layout/Sidebar'
import TableUsers from './TableUsers'

const Users = () => {
  return (
    <>
   <section className={`${styles.home}`}>
        <Sidebar active="user" />
        <div className={`${styles.homeContainer}`}>
            <TableUsers/>
            </div>
            </section>
    </>
  )
}

export default Users