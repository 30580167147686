import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import styles from "../../Styles/dashboard.module.css";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Select, TextInput, Textarea } from "@mantine/core";
import uploadimg from "../../assets/images/upload.jpg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";
import { useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { Button } from "@mantine/core";
import Environment from "../../config";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ItemDetails from "./ItemDetails";
import ItemSize from "./ItemSize";
import useSWR, { mutate, useSWRConfig } from "swr";
import { SegmentedControl } from "@mantine/core";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Progress } from "@mantine/core";
const EditItem = () => {
  const itemId = useParams();
  const { token } = useSelector((state) => state.user);
  const [itemLangDetails, setItemLangDetails] = useState([]);
  const [videoPermision, setPermision] = useState(
    localStorage.getItem("itemVideo")
  );
  const [imagepermision, setItemPermision] = useState(
    localStorage.getItem("itemPicpremision")
  );
  const [sizeChartLink, setSizeChartLink] = useState("");
  const [prodPic, setProdPic] = useState([]);
  const [prodVid, setProdVid] = useState([]);
  const [colorName, setColorName] = useState();
  const [colorItem, setColorItem] = useState();
  const [selectedColor, setSelectedColor] = useState(null);
  const [ItemColorImages, setColorImages] = useState([]);
  const fileInputRef = useRef(null);
  const [segmant, setSegmant] = useState("images");
  /*   const [formData, setFormData] = useState({
    video: null,
  });
  const addFile = useRef(null)
    const addFileInput = useRef(null)
    const imageContentRef = useRef(null);
    const imageFirmRef = useRef(null);
    const [imageUrl, setImage] = useState(null) */
  const handleColorSelection = (color) => {
    setSelectedColor(color);
    setColorName(color.colorName);
    setColorItem(color.itemColor);
    console.log(color, "co");
  };

    const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const filePromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        resolve(file);
      });
    });
    Promise.all(filePromises)
      .then((files) => {
        setColorImages((prevColorImages) => {
          if (Array.isArray(prevColorImages)) {
            return [...prevColorImages, ...files];
          } else {
            return [...files];
          }
        });
        event.target.value = null;
      })
      .catch((error) => {
        console.error("Error reading images:", error);
      });
  };  
  /* const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const maxFileSize = 1024 * 1024; // 1 MB in bytes
  
    const filePromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        if (file.size > maxFileSize) {
          reject(`File '${file.name}' is larger than 1 MB.`);
        } else {
          resolve(file);
        }
      });
    });
  
    Promise.all(filePromises)
      .then((files) => {
        setColorImages((prevColorImages) => {
          if (Array.isArray(prevColorImages)) {
            return [...prevColorImages, ...files];
          } else {
            return [...files];
          }
        });
        event.target.value = null;
      })
      .catch((error) => {
        toast.error(error);
      });
  }; */
  const fetcher = (args) => {
    const [url, token] = args;
    return axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setProdPic(response.data.data.productPic);
        setProdVid(response.data.data.productVedio);
        setSelectedColor(response.data.data.productPic[0]);
        setSizeChartLink(response.data.data.sizeChartLink);
        setItemLangDetails(response.data.data.itemLangDetails);
        return response.data.data;
      });
  };
  const { data, error } = useSWR(
    [
      `${Environment.baseURL}/api/admin/ImageDetails/getItemById?itemCode=${itemId.id}`,
      token,
    ],
    fetcher
  );
  if (error) console.log(error);
  if (data) {
  }
  function submitImages(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("token", token);
    formData.append("ItemCode", itemId.id);
    formData.append("ItemColor", colorItem);
    formData.append("ItemColorImages", ItemColorImages);

    for (const image of ItemColorImages) {
      formData.append("ItemColorImages", image);
    }
    for (let [key, value] of formData) {
      console.log(`${key}: ${value}`);
    }

    axios
      .post(`${Environment.baseURL2}/api/Item/addItemPic`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        toast.success("Upload Images Success");
        mutate([
          `${Environment.baseURL}/api/admin/ImageDetails/getItemById?itemCode=${itemId.id}`,
          token,
        ]);
        setColorImages([]);
      })
      .catch((err) => {
        toast.error(err);
      });
  }
  /*   let previewUploadVideo = (e) => {
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    setFormData((prevValue) => {
      return {
        ...prevValue,
        video: file,
      };
    });
  };
  function handleLogo() {
    let inputFileEvent = document.querySelector(".input-file-js")
    inputFileEvent.click()
} */
  const [videoLink, setVideoLink] = useState("");
  const [progress, setProgress] = useState(0);
  const [formData, setFormData] = useState({
    video: null,
  });
  console.log(formData.video);
  const addVideoInputRef = useRef(null);

  function handleVideo() {
    addVideoInputRef.current.click();
  }

  const previewUploadVideo = (e) => {
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    setFormData((prevValue) => {
      return {
        ...prevValue,
        video: file,
      };
    });
  };
  function submitVideos(e) {
    e.preventDefault();
    const videoData = new FormData();
    videoData.append("token", token);
    videoData.append("ItemCode", itemId.id);
    videoData.append("ItemColor", colorItem);
    videoData.append("ItemColorVedios", formData.video);

    axios
      .post(`${Environment.baseURL2}/api/Item/addItemOneVedio`, videoData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setProgress(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        },
      })
      .then((response) => {
        toast.success("Upload item video success");
        setFormData({
          video: null,
        });
        mutate([
          `${Environment.baseURL}/api/admin/ImageDetails/getItemById?itemCode=${itemId.id}`,
          token,
        ]);
      })
      .catch((err) => {
        toast.error(err);
      });
  }
  const handledelet = (item) => {
    console.log(item);
    axios
      .post(
        `${Environment.baseURL2}/api/Item/deleteItemPic`,
        {
          "picId": item,
  "token": token
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        toast.success("delete image Success");
        mutate([
          `${Environment.baseURL}/api/admin/ImageDetails/getItemById?itemCode=${itemId.id}`,
          token,
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handledeletVid = (item) => {
    console.log(item);
    axios
      .post(
        `${Environment.baseURL}/api/admin/ImageDetails/deleteItemVedio?vedioId=${item}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        toast.success("delete video Success");
        mutate([
          `${Environment.baseURL}/api/admin/ImageDetails/getItemById?itemCode=${itemId.id}`,
          token,
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <section className={`${styles.home}`}>
        <Sidebar active="dashboard" />
        <div className={`${styles.homeContainer}`}>
          <h3>Details of Item {itemId.id}</h3>
          <hr />
          <Row className={`${styles.row}`}>
            <Col className={`${styles.col1}`} xl="5">
              <h3>Base Information</h3>
              <ItemDetails itemLangDetails={itemLangDetails} />
              <h3>Item Media</h3>
              <div className={`${styles.base__body}`}>
                <h3 className={`${styles.color__title}`}>
                  Choose color to upload{" "}
                  <SegmentedControl
                    value={segmant}
                    onChange={setSegmant}
                    data={[
                      { label: "Images", value: "images" },
                      { label: "Videos", value: "videos" },
                    ]}
                  />
                </h3>
                <div className={`${styles.color__body}`}>
                  {segmant === "images" ? (
                    <>
                      {prodPic.map((color) => (
                        <div
                          key={color.colorHex}
                          style={{
                            backgroundColor: `#${color.colorHex}`,
                            cursor: "pointer",
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            boxShadow:
                              "rgba(0,0,0,0.05) 0px 6px 24px 0px, rgba(0,0,0,0.08) 0px 0px 0px 1px",
                          }}
                          className="mb-2"
                          onClick={() => handleColorSelection(color)}
                        ></div>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                  {segmant === "videos" ? (
                    <>
                      {prodVid.map((color) => (
                        <div
                          key={color.colorHex}
                          style={{
                            backgroundColor: `#${color.colorHex}`,
                            cursor: "pointer",
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            boxShadow:
                              "rgba(0,0,0,0.05) 0px 6px 24px 0px, rgba(0,0,0,0.08) 0px 0px 0px 1px",
                          }}
                          className="mb-2"
                          onClick={() => handleColorSelection(color)}
                        ></div>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {colorItem ? (
                  segmant === "images" ? (
                    <p className={`${styles.select__para}`}>
                      upload images for {colorName} color
                    </p>
                  ) : (
                    <p className={`${styles.select__para}`}>
                      upload videos for {colorName} color
                    </p>
                  )
                ) : (
                  ""
                )}
                {segmant === "images" ? (
                  <div className={`${styles.oldnew_img}`}>
                    {selectedColor?.images?.map((img) => (
                      <>
                        <div className={`${styles.delete__body}`}>
                          <img
                            alt=""
                            src={img.picLink}
                            key={img.id}
                            className={`${styles.imgcolor}`}
                          />
                          {imagepermision == 1 ? (
                            <div className={`${styles.ico}`}>
                              <DeleteForeverIcon
                                className={`${styles.delete__icon}`}
                                onClick={() => handledelet(img.id)}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ))}
                    {imagepermision == 1
                      ? colorItem && (
                          <>
                           {/*  <input
                              type="file"
                              onChange={handleImageUpload}
                              multiple
                              style={{ display: "none" }}
                              ref={fileInputRef}
                            />
                            {ItemColorImages.map((image, index) => (
                              <img
                                key={index}
                                src={URL.createObjectURL(image)}
                                alt=""
                                className={styles.uploadimges}
                              />
                            ))}
                            <img
                              alt=""
                              src={uploadimg}
                              onClick={() => fileInputRef.current.click()}
                              className={styles.uploadimges}
                            /> */}
                              <input
    type="file"
    onChange={(event) => handleImageUpload(event)}
    multiple
    style={{ display: "none" }}
    ref={fileInputRef}
  />
  {ItemColorImages.map((image, index) => (
    <img
      key={index}
      src={URL.createObjectURL(image)}
      alt=""
      className={styles.uploadimges}
    />
  ))}
  <img
    alt=""
    src={uploadimg}
    onClick={() => fileInputRef.current.click()}
    className={styles.uploadimges}
  />
                          </>
                        )
                      : ""}
                  </div>
                ) : (
                  ""
                )}
                {segmant === "videos" ? (
                  <div className={`${styles.oldnew_img}`}>
                    {selectedColor?.vedioColorInfos?.map((vid) => (
                      <>
                        <div className={`${styles.delete__body}`}>
                          <video className={`${styles.imgcolor}`} controls>
                            <source src={vid.vedioLink} type="video/mp4" />
                          </video>
                          {imagepermision == 1 ? (
                            <div className={`${styles.ico}`}>
                              <DeleteForeverIcon
                                className={`${styles.delete__icon}`}
                                onClick={() => handledeletVid(vid.id)}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ))}
                    {imagepermision == 1
                      ? colorItem && (
                          <>
                            {videoLink === "" && formData.video === null ? (
                              <>
                                <button
                                  className="file-btn2"
                                  onClick={handleVideo}
                                >
                                  <span>
                                    <CloudUploadIcon />
                                  </span>{" "}
                                  Upload video
                                </button>
                                <input
                                  className={`${styles.fileVideo} input-file-js d-none `}
                                  ref={addVideoInputRef}
                                  id="input-video"
                                  name="video"
                                  type="file"
                                  accept="video/*"
                                  onChange={previewUploadVideo}
                                />
                              </>
                            ) : (
                              <>
                                {videoLink === "" && (
                                  <>
                                    <div className={`${styles.item__video}`}>
                                      <button
                                        className="file-btn2"
                                        onClick={handleVideo}
                                      >
                                        <span>
                                          <CloudUploadIcon />
                                        </span>{" "}
                                        Upload video {formData.video?.name}
                                      </button>
                                      <div className={`${styles.prog__body}`}>
                                        <div className="w-100">
                                          <p className="m-0 p-0">
                                            {formData.video?.name}
                                          </p>
                                          <Progress
                                            color="rgba(120, 177, 235, 1)"
                                            value={progress}
                                            animated
                                            className={`${styles.progressbar}`}
                                          />
                                        </div>
                                        <span
                                          className={`${styles.progress__number}`}
                                        >
                                          {progress}%
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )
                      : ""}
                  </div>
                ) : (
                  ""
                )}
                {imagepermision == 1 && segmant === "images" ? (
                  <div className={`${styles.sub__btn}`}>
                    <Button type="button" onClick={submitImages}>
                      Submit
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                {imagepermision == 1 && segmant === "videos" ? (
                  <div className={`${styles.sub__btn}`}>
                    <Button type="button" onClick={submitVideos}>
                      Submit
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <h3>Item Size</h3>
              <ItemSize sizeChartLink={sizeChartLink} />
            </Col>
            <Col className={`${styles.col2}`} xl="5">
              <h3>Preview</h3>
              <div className={`${styles.base__body}`}>
                {selectedColor && segmant === "images" ? (
                  <Carousel
                    width={400}
                    autoPlay
                    interval="1000"
                    transitionTime="1000"
                    className={`${styles.carousel__image}`}
                  >
                    {selectedColor?.images?.map((image) => (
                      <img
                        key={image.id}
                        src={image.picLink}
                        alt={image.id}
                        style={{ display: "block" }}
                      />
                    ))}
                  </Carousel>
                ) : (
                  <p className={`${styles.noimg__para}`}>No image to show</p>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default EditItem;
