import React from 'react'
import Sidebar from '../../Layout/Sidebar'
import styles from '../../Styles/dashboard.module.css'
import { Col, Row } from 'react-bootstrap'
import TableItem from './TableItem'
const Dashboard = () => {

    return (
        <>
            <section className={`${styles.home}`}>
                <Sidebar active='dashboard' />
                <div className={`${styles.homeContainer}`}>
                   <TableItem/>
                </div>
            </section>
        </>
    )
}

export default Dashboard
