import React from "react";
import Sidebar from "../../Layout/Sidebar";
import style from "../../Styles/category.module.css";
import TableCategory from "./TableCategory";
import { ToastContainer } from "react-toastify";

const Category = () => {
  return (
    <>
      <section className={`${style.home}`}>
        <Sidebar active="category" />
        <div className={`${style.homeContainer}`}>
<TableCategory/>
        </div>
        <ToastContainer/>
      </section>
    </>
  );
};

export default Category;
