import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import styles from "../../Styles/banner.module.css";
import axios from "axios";
import { useSelector } from "react-redux";
import useSWR, { mutate, useSWRConfig } from "swr";
import Environment from "../../config";
import { Carousel } from "react-responsive-carousel";
import { Col, Row } from "react-bootstrap";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { toast } from "react-toastify";
import uploadimg from "../../assets/images/upload.jpg";
import { Modal, Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
const BannerCategory = () => {

    const [opened, { open, close }] = useDisclosure(false);
    const [selectedOption, setSelectedOption] = useState(``);
  
    const { token } = useSelector((state) => state.user);
    const [banner, setBanner] = useState([]);
    const [bannerpermision, setbannerPermision] = useState(
      localStorage.getItem("bannerPicpremision")
    );
    const fileInputRef = useRef(null);
    const fetcher = (args) => {
      const [url, token] = args;
      return axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          setBanner(response.data.data);
          return response.data.data;
        });
    };
    const { data, error } = useSWR(
      [`${Environment.baseURL}/api/admin/Banner/getBannersCateory`, token],
      fetcher
    );
    if (error) console.log(error);
    if (data) {
      console.log(data, "banner");
    }
    const [formData, setFormData] = useState({
      img: "",
    });
    const addFile = useRef(null);
    const addFileInput = useRef(null);
    const imageContentRef = useRef(null);
    const imageFirmRef = useRef(null);
    const [imageUrl, setImage] = useState(null);
    function handleLogo() {
      let inputFileEvent = document.querySelector(".input-file-js");
      inputFileEvent.click();
    }
    let previewUploadImage = (e) => {
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      let preViewLink = URL.createObjectURL(file);
      setImage(preViewLink);
      setFormData((prevValue) => {
        return {
          ...prevValue,
          img: file,
        };
      });
    };
  
    function submitImagesBanner(e) {
      e.preventDefault();
      const reqData = {
        token: token,
        BannerImages: formData.img,
        CategoryCode: selectedOption,
      };
      axios
        .post(
          `${Environment.baseURL2}/api/Banner/addBannerPicWithCategory`,
          reqData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            close();
            toast.success("Upload banner Success");
            setImage(null);
            mutate([
              `${Environment.baseURL}/api/admin/Banner/getBannersCateory`,
              token,
            ]);
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
    const handledelet = (item) => {
      console.log(item);
      axios
        .post(
          `${Environment.baseURL2}/api/Banner/deleteBannerPicWithCategory`,
          {
            bannerId: item,
            token: token,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.data) {
            toast.success("delete image Success");
            mutate([
              `${Environment.baseURL}/api/admin/Banner/getBannersCateory`,
              token,
            ]);
          } else {
            toast.error(response.data.errorMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const [subCategory, setSubCategory] = useState([]);
    const fetcher2 = (args) => {
      const [url, token] = args;
      return axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          setSubCategory(response.data.data);
          return response.data.data;
        });
    };
    const { subcategory, error2 } = useSWR(
      [`${Environment.baseURL}/api/admin/Category/getCategories`, token],
      fetcher2
    );
    if (error2) console.log(error);
    if (subcategory) {
      console.log(subcategory, "swr");
    }
    return (
      <>
        <section className={`${styles.home}`}>
          <Sidebar active="banner" />
          <div className={`${styles.homeContainer}`}>
            <Row>
              <Col>
                <div className={`${styles.base__body}`}>
                  <div className={`${styles.banner__body}`}>
                    {banner?.map((img) => (
                      <>
                        <div className={`${styles.delete__body}`}>
                          <img
                            alt=""
                            src={img.picLink}
                            key={img.id}
                            className={`${styles.imgcolor}`}
                          />
                          {bannerpermision == 1 ? (
                            <div className={`${styles.ico}`}>
                              <DeleteForeverIcon
                                className={`${styles.delete__icon}`}
                                onClick={() => handledelet(img.id)}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ))}
                  </div>
  
                  <></>
                  {bannerpermision == 1 ? (
                    <>
                      <Modal
                        opened={opened}
                        onClose={close}
                        title={`Banner details`}
                      >
                        {bannerpermision == 1 ? (
                          <>
                            <select
                              value={selectedOption}
                              onChange={(e) => setSelectedOption(e.target.value)}
                              className={`${styles.select}`}
                            >
                              <option>Choose Category</option>
  
                              {subCategory.map((sub) => (
                                <>
                                  <option value={sub.code} key={sub.code}>
                                    {sub.name}
                                  </option>
                                </>
                              ))}
                            </select>
                            <div className={`${styles.im}`}>
                              <input
                                className={`${styles.fileImg}  input-file-js`}
                                ref={(e) => {
                                  addFileInput.current = e;
                                }}
                                id="input-file"
                                name="img"
                                type="file"
                                onChange={(e) => {
                                  previewUploadImage(e);
                                }}
                              />
                              {imageUrl == null ? (
                                <>
                                  <div
                                    ref={addFile}
                                    onClick={() => {
                                      handleLogo();
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={uploadimg}
                                      ref={imageFirmRef}
                                      className={styles.uploadimges}
                                    />
                                  </div>
                                </>
                              ) : (
                                <div
                                  ref={addFile}
                                  onClick={() => {
                                    handleLogo();
                                  }}
                                >
                                  <img
                                    className={`${styles.img}`}
                                    ref={imageContentRef}
                                    src={imageUrl}
                                    alt=""
                                  />
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                        ""
                        )}
                        <div className={`${styles.sub__btn}`}>
                          <Button type="button" onClick={submitImagesBanner}>
                            Submit banner
                          </Button>
                        </div>
                      </Modal>
                      <div className="btn__location mt-3">
                        <Button onClick={open}>
                          {" "}
                          Upload Banner With Category
                        </Button>
                      </div>
                    </>
                  ) : (
                    
                   ''
                  )}
                </div>
              </Col>
              <Col>
                <div className={`${styles.base__body}`}>
                  <Carousel
                    width={400}
                    autoPlay
                    interval="1000"
                    transitionTime="1000"
                    className={`${styles.carousel__image}`}
                  >
                    {banner.map((image) => (
                      <img
                        key={image.id}
                        src={image.picLink}
                        alt={image.id}
                        style={{ display: "block" }}
                      />
                    ))}
                  </Carousel>
                </div>
              </Col>
            </Row>
          </div>
        </section>

    </>
  )
}

export default BannerCategory