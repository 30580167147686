import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import style from "../../Styles/setup.module.css";
import Environment from "../../config";
import useSWR, { mutate, useSWRConfig } from "swr";
import { useSelector } from "react-redux";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { Button } from "@mantine/core";
import { ToastContainer, toast } from "react-toastify";

const HomeSetup = () => {
  const { token } = useSelector((state) => state.user);
  const [subCategory, setSubCategory] = useState([]);
  const fetcher = (args) => {
    const [url, token] = args;
    return axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setSubCategory(response.data.data);
        return response.data.data;
      });
  };
  const { subcategory, error } = useSWR(
    [`${Environment.baseURL}/api/admin/Category/getSubCategories`, token],
    fetcher
  );
  if (error) console.log(error);
  if (subcategory) {
    console.log(subcategory, "swr");
  }
  const [selectedOption, setSelectedOption] = useState(``);
  const [selectedOptionsecond, setSelectedOptionsecond] = useState(``);
  const [selectedOptionThird, setSelectedOptionThird] = useState(``);
  const [selectedOptionFourth, setSelectedOptionFourth] = useState(``);
  const [selectedOptionSubOne, setSelectedOptionSubOne] = useState(``);
  const [selectedOptionSubTwo, setSelectedOptionSubTwo] = useState(``);
  const [selectedOptionSubThree, setSelectedOptionSubThree] = useState(``);

  const [items, setItems] = useState([]);
  const [itemsTwo, setItemsTwo] = useState([]);
  const [itemsThree, setItemsThree] = useState([]);

  const [selectedOptionProduct1, setSelectedOptionProduct1] = useState(``);
  const [selectedOptionProduct2, setSelectedOptionProduct2] = useState(``);
  const [selectedOptionProduct3, setSelectedOptionProduct3] = useState(``);
  const [selectedOptionProduct4, setSelectedOptionProduct4] = useState(``);

  const [selectedOptionProduct21, setSelectedOptionProduct21] = useState(``);
  const [selectedOptionProduct22, setSelectedOptionProduct22] = useState(``);
  const [selectedOptionProduct23, setSelectedOptionProduct23] = useState(``);
  const [selectedOptionProduct24, setSelectedOptionProduct24] = useState(``);

  const [selectedOptionProduct31, setSelectedOptionProduct31] = useState(``);
  const [selectedOptionProduct32, setSelectedOptionProduct32] = useState(``);
  const [selectedOptionProduct33, setSelectedOptionProduct33] = useState(``);
  const [selectedOptionProduct34, setSelectedOptionProduct34] = useState(``);
  useEffect(() => {
    if (selectedOptionSubOne) {
      axios
        .get(`${Environment.baseURL}/api/admin/KokyHomeSetup/getProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const filtered = response.data.data.filter(
            (item) => item.subCategoryCode === Number(selectedOptionSubOne)
          );
          setItems(filtered);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [selectedOptionSubOne]);

  useEffect(() => {
    if (selectedOptionSubTwo) {
      axios
        .get(`${Environment.baseURL}/api/admin/KokyHomeSetup/getProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const filtered = response.data.data.filter(
            (item) => item.subCategoryCode === Number(selectedOptionSubTwo)
          );
          setItemsTwo(filtered);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [selectedOptionSubTwo]);

  useEffect(() => {
    if (selectedOptionSubThree) {
      axios
        .get(`${Environment.baseURL}/api/admin/KokyHomeSetup/getProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const filtered = response.data.data.filter(
            (item) => item.subCategoryCode === Number(selectedOptionSubThree)
          );
          setItemsThree(filtered);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [selectedOptionSubThree]);

  function submitFirstSubCategory(e) {
    e.preventDefault();
    const reqData = {
      firstSubCategoryCode: selectedOption,
      secondSubCategoryCode: selectedOptionsecond,
      sectionNo: 1,
    };
    axios
      .post(
        `${Environment.baseURL}/api/admin/KokyHomeSetup/saveSubCategorySection`,
        reqData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          toast.success("Upload SubCategory To Home Success");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function submitFirstProductSubCategory(e) {
    e.preventDefault();
    const reqData = {
      subCategoryCode: selectedOptionSubOne,
      sectionNo: 3,
      itemsCode1: selectedOptionProduct1,
      itemsCode2: selectedOptionProduct2,
      itemsCode3: selectedOptionProduct3,
      itemsCode4: selectedOptionProduct4,
    };
    axios
      .post(
        `${Environment.baseURL}/api/admin/KokyHomeSetup/saveProductSection`,
        reqData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.data) {
          toast.success("Upload  Products of SubCategory To Home Success");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }
  function submitSecondSubCategory(e) {
    e.preventDefault();
    const reqData = {
      firstSubCategoryCode: selectedOptionThird,
      secondSubCategoryCode: selectedOptionFourth,
      sectionNo: 2,
    };
    axios
      .post(
        `${Environment.baseURL}/api/admin/KokyHomeSetup/saveSubCategorySection`,
        reqData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.data) {
          toast.success("Upload SubCategory To Home Success");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }
  function submitSecondProductSubCategory(e) {
    e.preventDefault();
    const reqData = {
      subCategoryCode: selectedOptionSubTwo,
      sectionNo: 4,
      itemsCode1: selectedOptionProduct21,
      itemsCode2: selectedOptionProduct22,
      itemsCode3: selectedOptionProduct23,
      itemsCode4: selectedOptionProduct24,
    };
    axios
      .post(
        `${Environment.baseURL}/api/admin/KokyHomeSetup/saveProductSection`,
        reqData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.data) {
          toast.success("Upload  Products of SubCategory To Home Success");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }
  function submitThirdProductSubCategory(e) {
    e.preventDefault();
    const reqData = {
      subCategoryCode: selectedOptionSubThree,
      sectionNo: 5,
      itemsCode1: selectedOptionProduct31,
      itemsCode2: selectedOptionProduct32,
      itemsCode3: selectedOptionProduct33,
      itemsCode4: selectedOptionProduct34,
    };
    axios
      .post(
        `${Environment.baseURL}/api/admin/KokyHomeSetup/saveProductSection`,
        reqData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.data) {
          toast.success("Upload  Products of SubCategory To Home Success");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }
  return (
    <>
      <section className={`${style.home}`}>
        <Sidebar active="setup" />
        <div className={`${style.homeContainer}`}>
          <Row className="mb-5">
            <h3 className={`${style.sub__title}`}>تصنيف الفرعي الاول</h3>
            <Col>
              <select
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose first SubCategory</option>

                {subCategory.map((sub) => (
                  <>
                    <option value={sub.code} key={sub.code}>
                      {sub.name}
                    </option>
                  </>
                ))}
              </select>
            </Col>
            <Col>
              <select
                value={selectedOptionsecond}
                onChange={(e) => setSelectedOptionsecond(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose second SubCategory</option>

                {subCategory.map((sub) => (
                  <>
                    <option value={sub.code} key={sub.code}>
                      {sub.name}
                    </option>
                  </>
                ))}
              </select>
            </Col>
          </Row>
          <div className={`${style.sub__btn}`}>
            <Button type="button" onClick={submitFirstSubCategory}>
              Submit
            </Button>
          </div>
          <hr />

          <h3 className={`${style.sub__title} mt-5`}>   منتجات التصنيف الفرعي الاول</h3>
          <select
            value={selectedOptionSubOne}
            onChange={(e) => setSelectedOptionSubOne(e.target.value)}
            className={`${style.select}`}
          >
            <option>Choose SubCategory</option>
            {subCategory.map((sub) => (
              <>
                <option value={sub.code} key={sub.code}>
                  {sub.name}
                </option>
              </>
            ))}
          </select>
          <Row className="mt-3">
            <Col>
              <select
                value={selectedOptionProduct1}
                onChange={(e) => setSelectedOptionProduct1(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose product one</option>
                {items.map((prod) => (
                  <>
                    <option value={prod.itemCode} key={prod.itemCode}>
                      {prod.itemCode}-{prod.itemName}
                    </option>
                  </>
                ))}
              </select>
            </Col>
            <Col>
              <select
                value={selectedOptionProduct2}
                onChange={(e) => setSelectedOptionProduct2(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose product Two</option>
                {items.map((prod) => (
                  <>
                    <option value={prod.itemCode} key={prod.itemCode}>
                      {prod.itemCode}-{prod.itemName}
                    </option>
                  </>
                ))}
              </select>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <select
                value={selectedOptionProduct3}
                onChange={(e) => setSelectedOptionProduct3(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose product Three</option>
                {items.map((prod) => (
                  <>
                    <option value={prod.itemCode} key={prod.itemCode}>
                      {prod.itemCode}-{prod.itemName}
                    </option>
                  </>
                ))}
              </select>
            </Col>
            <Col>
              <select
                value={selectedOptionProduct4}
                onChange={(e) => setSelectedOptionProduct4(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose product Four</option>
                {items.map((prod) => (
                  <>
                    <option value={prod.itemCode} key={prod.itemCode}>
                      {prod.itemCode}-{prod.itemName}
                    </option>
                  </>
                ))}
              </select>
            </Col>
          </Row>
          <div className={`${style.sub__btn}`}>
            <Button type="button" onClick={submitFirstProductSubCategory}>
              Submit
            </Button>
          </div>
          <hr />


          <Row className="mb-5">
            <h3 className={`${style.sub__title}`}>تصنيف الفرعي الثاني</h3>
            <Col>
              <select
                value={selectedOptionThird}
                onChange={(e) => setSelectedOptionThird(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose third SubCategory</option>

                {subCategory.map((sub) => (
                  <>
                    <option value={sub.code} key={sub.code}>
                      {sub.name}
                    </option>
                  </>
                ))}
              </select>
            </Col>
            <Col>
              <select
                value={selectedOptionFourth}
                onChange={(e) => setSelectedOptionFourth(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose fourth SubCategory</option>

                {subCategory.map((sub) => (
                  <>
                    <option value={sub.code} key={sub.code}>
                      {sub.name}
                    </option>
                  </>
                ))}
              </select>
            </Col>
          </Row>
          <div className={`${style.sub__btn}`}>
            <Button type="button" onClick={submitSecondSubCategory}>
              Submit
            </Button>
          </div>
          <hr />


          <h3 className={`${style.sub__title} mt-5`}>   منتجات التصنيف الفرعي الثاني</h3>
          <select
            value={selectedOptionSubTwo}
            onChange={(e) => setSelectedOptionSubTwo(e.target.value)}
            className={`${style.select}`}
          >
            <option>Choose SubCategory</option>
            {subCategory.map((sub) => (
              <>
                <option value={sub.code} key={sub.code}>
                  {sub.name}
                </option>
              </>
            ))}
          </select>
          <Row className="mt-3">
            <Col>
              <select
                value={selectedOptionProduct21}
                onChange={(e) => setSelectedOptionProduct21(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose product one</option>
                {itemsTwo.map((prod) => (
                  <>
                    <option value={prod.itemCode} key={prod.itemCode}>
                      {prod.itemCode}-{prod.itemName}
                    </option>
                  </>
                ))}
              </select>
            </Col>
            <Col>
              <select
                value={selectedOptionProduct22}
                onChange={(e) => setSelectedOptionProduct22(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose product Two</option>
                {itemsTwo.map((prod) => (
                  <>
                    <option value={prod.itemCode} key={prod.itemCode}>
                      {prod.itemCode}-{prod.itemName}
                    </option>
                  </>
                ))}
              </select>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <select
                value={selectedOptionProduct23}
                onChange={(e) => setSelectedOptionProduct23(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose product Three</option>
                {itemsTwo.map((prod) => (
                  <>
                    <option value={prod.itemCode} key={prod.itemCode}>
                      {prod.itemCode}-{prod.itemName}
                    </option>
                  </>
                ))}
              </select>
            </Col>
            <Col>
              <select
                value={selectedOptionProduct24}
                onChange={(e) => setSelectedOptionProduct24(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose product Four</option>
                {itemsTwo.map((prod) => (
                  <>
                    <option value={prod.itemCode} key={prod.itemCode}>
                      {prod.itemCode}-{prod.itemName}
                    </option>
                  </>
                ))}
              </select>
            </Col>
          </Row>
          <div className={`${style.sub__btn}`}>
            <Button type="button" onClick={submitSecondProductSubCategory}>
              Submit
            </Button>
          </div>
          <hr />



          <h3 className={`${style.sub__title} mt-5`}>   منتجات التصنيف الفرعي الثالث</h3>
          <select
            value={selectedOptionSubThree}
            onChange={(e) => setSelectedOptionSubThree(e.target.value)}
            className={`${style.select}`}
          >
            <option>Choose SubCategory</option>
            {subCategory.map((sub) => (
              <>
                <option value={sub.code} key={sub.code}>
                  {sub.name}
                </option>
              </>
            ))}
          </select>
          <Row className="mt-3">
            <Col>
              <select
                value={selectedOptionProduct31}
                onChange={(e) => setSelectedOptionProduct31(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose product one</option>
                {itemsThree.map((prod) => (
                  <>
                    <option value={prod.itemCode} key={prod.itemCode}>
                      {prod.itemCode}-{prod.itemName}
                    </option>
                  </>
                ))}
              </select>
            </Col>
            <Col>
              <select
                value={selectedOptionProduct32}
                onChange={(e) => setSelectedOptionProduct32(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose product Two</option>
                {itemsThree.map((prod) => (
                  <>
                    <option value={prod.itemCode} key={prod.itemCode}>
                      {prod.itemCode}-{prod.itemName}
                    </option>
                  </>
                ))}
              </select>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <select
                value={selectedOptionProduct33}
                onChange={(e) => setSelectedOptionProduct33(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose product Three</option>
                {itemsThree.map((prod) => (
                  <>
                    <option value={prod.itemCode} key={prod.itemCode}>
                      {prod.itemCode}-{prod.itemName}
                    </option>
                  </>
                ))}
              </select>
            </Col>
            <Col>
              <select
                value={selectedOptionProduct34}
                onChange={(e) => setSelectedOptionProduct34(e.target.value)}
                className={`${style.select}`}
              >
                <option>Choose product Four</option>
                {itemsThree.map((prod) => (
                  <>
                    <option value={prod.itemCode} key={prod.itemCode}>
                      {prod.itemCode}-{prod.itemName}
                    </option>
                  </>
                ))}
              </select>
            </Col>
          </Row>
          <div className={`${style.sub__btn}`}>
            <Button type="button" onClick={submitThirdProductSubCategory}>
              Submit
            </Button>
          </div>
          <hr />
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default HomeSetup;
