import React from 'react'
import Sidebar from '../../Layout/Sidebar'
import styles from '../../Styles/branch.module.css'
import TableBranch from './TableBranch'
const Branch = () => {
  return (
    <>
     <section className={`${styles.home}`}>
        <Sidebar active="Branch" />
        <div className={`${styles.homeContainer}`}>
          <h3>Branch Details</h3>
          <TableBranch/>
        </div>
        
        </section>

    </>
  )
}

export default Branch