import React, { useState, useRef, useEffect } from "react";
import { Select, TextInput, Textarea } from "@mantine/core";
import Form from "react-bootstrap/Form";
import styles from "../../Styles/dashboard.module.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import Environment from '../../config';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify'
import { ToastContainer } from "react-toastify";
import { Button } from '@mantine/core';
import { mutate } from "swr";
const ItemDetails = ({ itemLangDetails }) => {
    const itemId = useParams();
    const { token } = useSelector((state) => state.user);
    const [title, setTitle] = useState("");
    const [textarea, setTextarea] = useState("");
    const [selectedOption, setSelectedOption] = useState(``);
    const [selectLang, setSelect] = useState([]);
    useEffect(() => {
        axios
            .get(
                `${Environment.baseURL}/api/admin/AdminOptions/getLanguage`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                setSelect(response.data.data);
                const filteredData = response.data.data.filter(item => item.defaultFlage === 1);
                setSelectedOption(filteredData.length > 0 ? filteredData[0].code : null);
            })
            .catch((err) => {
                console.log(err.response);
            });
    }, []);
    
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        const SelectedOption = parseInt(event.target.value);
        const selectedItem = itemLangDetails.find((item) => item.languageCode === SelectedOption);
        setTitle(selectedItem?.itemName ?? "");
        setTextarea(selectedItem?.itemDesc ?? "");
    };
    useEffect(() => {
        const defaultSelectedItem = itemLangDetails.find(item => item.languageCode === 1);
        setTitle(defaultSelectedItem?.itemName || "");
        setTextarea(defaultSelectedItem?.itemDesc || "");
        setSelectedOption('1')
    }, [itemLangDetails]);

    function submitDetails(e) {
        e.preventDefault();
        const reqData = {
            itemCode: itemId.id,
            languageCode: selectedOption,
            itemName: title,
            itemDesc: textarea
        };
        axios
            .post(`${Environment.baseURL}/api/admin/ItemDetails/addItemDetails`, reqData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                toast.success('Edit Item Success');
            
            })
            .catch((err) => {
                toast.error(err);
            });
        mutate([`${Environment.baseURL}/api/admin/ImageDetails/getItemById?itemCode=${itemId.id}`, token])

    }
  return (
    <>
          <div className={`${styles.base__body}`}>
              <select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className={`${styles.select}`}
              >
                  <option>Choose Language</option>

                  {selectLang.map((option) => (
                      <option key={option.code} value={option.code}>
                          {option.name}
                      </option>
                  ))}
              </select>
            
                  <>
                      <TextInput
                          className={`${styles.title}`}
                          label={`Title `}
                          value={title}
                          onChange={(event) => setTitle(event.currentTarget.value)}
                      />
                      <Textarea
                          className={`${styles.description}`}
                          label={`Description`}
                          value={textarea}
                          onChange={(event) => setTextarea(event.currentTarget.value)}
                          autosize
                          minRows={4}
                          maxRows={5}
                      />
                  </>
              
              <div className={`${styles.sub__btn}`}>
                  <Button type="button" onClick={submitDetails}>Submit</Button>
              </div>
          </div> 
    </>
  )
}

export default ItemDetails
