import React,{useState,useRef,useEffect} from 'react'
import {Modal, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import styles from '../../Styles/dashboard.module.css'
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify'
import { ToastContainer } from "react-toastify";
import Environment from '../../config';
import { mutate } from 'swr';
import { TextInput } from "@mantine/core";
const EditCategory = ({data}) => {
  const { token } = useSelector((state) => state.user);
  const [opened, { open, close }] = useDisclosure(false);
  const [title, setTitle] = useState(``);
  const [address, setAddress] = useState(``);
  const [link, setLink] = useState(data.locationLink);
const[branchLang,setBranchLang]=useState(data?.branchDetails)
  const [selectedOption, setSelectedOption] = useState(``);
  const [selectLang, setSelect] = useState([]);
  console.log(branchLang)
  function handleUpload(e) {
    e.preventDefault();
    const reqData = {
        "languageCode": selectedOption,
        "branchCode": data.branchCode,
        "name": title,
        "adress": address
      };
    axios
        .post(`${Environment.baseURL}/api/admin/BranchLang/manageBranchData`, reqData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => {
          console.log(response)
          mutate([
            `${Environment.baseURL}/api/admin/BranchLang/getAllBranchs`,
            token,
          ]);
          close()
           toast.success('Edit Branch successfully');
               
        })
        .catch((err) => {
            toast.error(err.response.data.message);
            console.log(err)
  
        });
  }
  useEffect(() => {
      axios
          .get(
              `${Environment.baseURL}/api/admin/AdminOptions/getLanguage`,
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
              }
          )
          .then((response) => {
              setSelect(response.data.data);
              const filteredData = response.data.data.filter(item => item.defaultFlage === 1);
              setSelectedOption(filteredData.length > 0 ? filteredData[0].code : null);
          })
          .catch((err) => {
              console.log(err.response);
          });
  }, []);
  
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    const SelectedOption = parseInt(event.target.value);
    const selectedItem = branchLang.find((item) => item.languageCode === SelectedOption);
    setTitle(selectedItem?.name ?? "");
    setAddress(selectedItem?.adress ?? "");
};
useEffect(() => {
    const defaultSelectedItem = branchLang.find(item => item.languageCode === 1);
    setTitle(defaultSelectedItem?.name || "");
    setAddress(defaultSelectedItem?.adress || "");
    setSelectedOption('1')
}, [branchLang]);

  return (
    <>
 <Modal opened={opened} onClose={close} title={` Branch details`} >
 <select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className={`${styles.select}`}
              >
                  <option>Choose Language</option>

                  {selectLang.map((option) => (
                      <option key={option.code} value={option.code}>
                          {option.name}
                      </option>
                  ))}
              </select>
 <TextInput
                          className={`${styles.title}`}
                          label={`Branch Name `}
                          value={title}
                          onChange={(event) => setTitle(event.currentTarget.value)}
                      />
                     
                      <TextInput
                          className={`${styles.title} mb-2`}
                          label={`Branch Address `}
                          value={address}
                          onChange={(event) => setAddress(event.currentTarget.value)}
                      />
                     

                    <div className={`${styles.sub__btn}`}>
                    <Button type='button' onClick={handleUpload}>Submit</Button>
                    </div>
      </Modal>

      <Button onClick={open}> Edit Branch</Button>
    </>
  )
}

export default EditCategory