import { useMemo,useEffect,useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, Typography } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify'
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import Environment from '../../config';
import useSWR, { mutate, useSWRConfig } from 'swr'
import EditBranch from "./EditBranch";
import { TextInput } from "@mantine/core";
import styles from '../../Styles/dashboard.module.css'
import EditLocation from './EditLocation';

const TableBranch = () => {
  const [data, setCategory] = useState([]);
  const { token } = useSelector((state) => state.user);
  const [selectedOption, setSelectedOption] = useState(``);
  const [selectLang, setSelect] = useState([]);
  const fetcher = (args) => {
    const [url, token] = args
    return axios
      .get(url, { headers: { Authorization: `Bearer ${ token }` }
})
      .then((response) => {
        
        setCategory(response.data.data);
        return response.data.data 
      }  
      )
   }
const { category, error } = useSWR(
  [`${Environment.baseURL}/api/admin/BranchLang/getAllBranchs`, token],
  fetcher, 
);
if (error) console.log(error);
if (data){
  console.log(data, 'swr');
} 


useEffect(() => {
    axios
        .get(
            `${Environment.baseURL}/api/admin/AdminOptions/getLanguage`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        .then((response) => {
            setSelect(response.data.data);
            const filteredData = response.data.data.filter(item => item.defaultFlage === 1);
            setSelectedOption(filteredData.length > 0 ? filteredData[0].code : null);
        })
        .catch((err) => {
            console.log(err.response);
        });
}, []);
 const handleOptionChange = (event) => {
  setSelectedOption(event.target.value);
};

  const columns = useMemo(
    () => [
        {
            id: 'employee', 
            columns: [
                {
                    accessorKey: 'branchName', 
                    enableClickToCopy: true,
                    header: `Branch Name`
                },
                {
                    accessorKey: 'branchAddress',
                    enableClickToCopy: true,
                    header: `Branch Address`,
                },
                {
                    accessorKey: 'tel',
                    enableClickToCopy: true,
                    header: `Branch Phone`,
                },
            ],
        },
    ],
    [],
);
const table = useMaterialReactTable({
  columns,
  data,
  enableExpandAll: false, //disable expand all button
  muiDetailPanelProps: () => ({
    sx: (theme) => ({
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255,210,244,0.1)'
          : 'rgba(0,0,0,0.1)',
    }),
  }),
  //custom expand button rotation
  muiExpandButtonProps: ({ row, table }) => ({
    onClick: () => {table.setExpanded({ [row.id]: !row.getIsExpanded() })},
    sx: {
      transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
      transition: 'transform 0.2s',
    },
  }),
  
  //conditionally render detail panel
  renderDetailPanel: ({ row ,cell}) => {
    const selectedBranchDetails=row.original.branchDetails.find(
      (item) => item.languageCode == parseInt(selectedOption)
    )
    return (
      <Row>
        <Col>
        <select
                  value={selectedOption}
                  onChange={handleOptionChange}
                  className={`${styles.select}`}
              >
                  <option>Choose Language</option>

                  {selectLang.map((option) => (
                      <option key={option.code} value={option.code}>
                          {option.name}
                      </option>
                  ))}
              </select>
          <TextInput
            className={`${styles.title}`}
            label={`Branch Name`}
            value={selectedBranchDetails?.name}
            readOnly
                      
          />
          <TextInput
            className={`${styles.title} mb-2`}
            label={`Branch Address`}
            value={selectedBranchDetails?.adress}
            readOnly
          />
          <EditBranch data={row.original}/>
        </Col>
        <Col>
        <TextInput
            className={`${styles.title} mb-2`}
            label={`Branch Location`}
            value={row.original.locationLink}
            readOnly
          />
          <EditLocation data={row.original}/>
        </Col>
        <ToastContainer/>
      </Row>
    );
  },
   
   
});

return <MaterialReactTable table={table} />;
};

export default TableBranch;
