import React, { useState, useRef,useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import styles from "../../Styles/banner.module.css";
import { Button } from "@mantine/core";
import Environment from "../../config";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import useSWR, { mutate } from "swr";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Progress } from '@mantine/core';

const HomeVideo = () => {
  const { token } = useSelector((state) => state.user);
  const [videoLink, setVideoLink] = useState("");
  const [progress, setProgress] = useState(0);


  const fetcher = (args) => {
    const [url, token] = args;
    return axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setVideoLink(response.data.data);
        return response.data.data;
      });
  };

  const { data, error } = useSWR(
    [`${Environment.baseURL}/api/admin/Banner/getVideoUrl`, token],
    fetcher
  );

  if (error) console.log(error);
  if (data) {
    console.log(data, "banner");
  }
  const addVideoInputRef = useRef(null);

  function handleVideo() {
    addVideoInputRef.current.click();
  }
   const [formData, setFormData] = useState({
    video: null,
  });
  console.log(formData.video);

 

  const previewUploadVideo = (e) => {
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    setFormData((prevValue) => {
      return {
        ...prevValue,
        video: file,
      };
    });
  };
  console.log(formData.video?.name);

 
  
  function submitVideoSize(e) {
    e.preventDefault();
    const reqData = new FormData();
    reqData.append("token", token);
    reqData.append("Video", formData.video);

    axios
      .post(`${Environment.baseURL2}/api/Banner/addWebVideo`, reqData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setProgress(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        },
      })
      .then((response) => {
        console.log(response);
        toast.success("Upload video Success");
        mutate([`${Environment.baseURL}/api/admin/Banner/getVideoUrl`, token]);
      })
      .catch((err) => {
        console.log(err);
      });
  } 

  function deleteVideoSize(e) {
    e.preventDefault();

    axios
      .post(`${Environment.baseURL}/api/admin/Banner/deleteBannerVideo`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
       
      })
      .then((response) => {
        console.log(response);
        toast.success("Delete video Success");
        mutate([`${Environment.baseURL}/api/admin/Banner/getVideoUrl`, token]);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <section className={`${styles.home}`}>
        <Sidebar active="video" />
        <div className={`${styles.homeContainer}`}>
          <div className={`${styles.base__body}`}>
            <p className={`${styles.color__title}`}>Upload Video For Home:</p>
            {videoLink === "" && formData.video === null ? (
              <>
                <button className="file-btn" onClick={handleVideo}>
                  <span>
                    <CloudUploadIcon />
                  </span>{" "}
                  Upload video
                </button>
                <input
                  className={`${styles.fileVideo} input-file-js d-none `}
                  ref={addVideoInputRef}
                  id="input-video"
                  name="video"
                  type="file"
                  accept="video/*"
                  onChange={previewUploadVideo}
                />
              </>
            ) : (
              <>
                {videoLink === "" && (
                  <>
                    <button className="file-btn" onClick={handleVideo}>
                      <span>
                        <CloudUploadIcon />
                      </span>{" "}
                      Upload video {formData.video?.name}
                    </button>
                    <div className={`${styles.prog__body}`}>
                      <div className="w-100">
                        <p className="m-0 p-0">{formData.video?.name}</p>
                        <Progress
                          color="rgba(120, 177, 235, 1)"
                          value={progress}
                          animated
                          className={`${styles.progressbar}`}
                        />
                      </div>
                      <span className={`${styles.progress__number}`}>
                        {progress}%
                      </span>
                    </div>
                  </>
                )}
              </>
            )}

            {videoLink && (
              <video className={styles.videoContent} controls>
                <source src={videoLink} type="video/mp4" />
              </video>
            )}
             {videoLink && (
              <div className={`${styles.sub__btn}`}>
              <Button type="button" onClick={deleteVideoSize} >
                Delete video
              </Button>
            </div>
            )}
            {videoLink === ""?
            <div className={`${styles.sub__btn}`}>
              <Button type="button" onClick={submitVideoSize} >
                Submit video
              </Button>
            </div>
            :
            ''
             }
          </div>

        </div>

      </section>
    </>
  );
};

export default HomeVideo;
